






import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { saveAs } from "file-saver";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      loaded: false,
    });

    const getReport = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/report`, { responseType: "blob" })
        .then((response) => {
          saveAs(response.data, `raport-${root.$route.params.id}.xlsx`);
          response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    return { state, getReport };
  },
});
